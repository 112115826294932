import { useEffect } from 'react'

import { styles } from './styles'

type CountdownProps = {
  parsedEndDateOfCountdown?: string
  theme?: string
  setIsCountdownExpired?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  countdownId: string
}

export const Countdown = ({
  parsedEndDateOfCountdown,
  theme,
  setIsCountdownExpired,
  countdownId,
}: CountdownProps) => {
  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !parsedEndDateOfCountdown ||
      !setIsCountdownExpired
    ) {
      return undefined
    }

    const dateLimit =
      new Date(parsedEndDateOfCountdown).getTime() / 1000 + 3600 / 60 / 60 / 24

    new window.FlipDown(dateLimit, countdownId, {
      theme: theme ?? 'light',
      headings: ['D', 'H', 'M', 'S'],
    })
      .start()
      .ifEnded(() => {
        setIsCountdownExpired(true)
      })

    const styleElement = document.createElement('style')

    styleElement.innerHTML = styles

    document.head.appendChild(styleElement)

    return () => {
      document.head.removeChild(styleElement)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className="flipdown" id={countdownId} />
}
